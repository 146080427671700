
const LMS_TOKEN: string = 'LmsToken';
const User_Permissions: string = 'UserPermissions'; 

class SessionStorageService {

    getAuthorizationToken (): string{
        return this._getItem(LMS_TOKEN, '');
    }

    getUserPermissions (): { [key: string]: string; }{
        return this._getItem(User_Permissions, {});
    }

    setAuthorizationToken (token: string){
        this._setItem(LMS_TOKEN, token);        
    }

    setUserPermissions (permissions: { [key: string]: string; }){
        this._setItem(User_Permissions, permissions);        
    }
    
    private _getItem<T>(key: string, defaultValue: T) : T {
        const value = sessionStorage.getItem(key);
  
        if (value) {
            return JSON.parse(value) as T;
        }
    
        return defaultValue;
    }

    private _setItem(key: string, value: any) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

}

const sessionStorageService = new SessionStorageService();

export { sessionStorageService };
