import { Icon, Text } from "../../../common/components";
import styles from './PasswordRule.module.scss';

interface IComponentProperties {
    password: string;
    label: string;
    validation: string;
}

export function PasswordRule(props: IComponentProperties) {
    const rulePassed: boolean = new RegExp(props.validation).test(props.password);
    const rulePassedStyle = rulePassed ? styles['is-passed'] : '';

    return (
        <section className={`${styles['PasswordRule']} ${rulePassedStyle}`}>
            <span className={styles['PasswordRule-icon']}>
                {rulePassed ? (
                    <Icon ariaHidden name="check_circle" isFilled={true} size={24} />
                ) : (
                    <Icon ariaHidden name="circle" size={24} />
                )}
            </span>
            <p>
                <Text fontStyle="label-medium" value={props.label} />
            </p>
        </section>
    );
}