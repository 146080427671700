import { useEffect, useState } from 'react';
import { Form, Navigate } from 'react-router-dom';
import { OutlinedTextField, FilledButton, Icon, FilledTonalButton } from '../../../common/components/material';

import styles from './ResetPassword.module.scss';
import {  ActionStatus, Result } from '../../../store';
import { BannerMessage, Text } from '../../../common/components';
import { authenticationService } from '../../../store/slices/authentication';
import { usePageStrings } from '../../../common/hooks';
import { appLogService } from '../../../store/slices/appLog';

export function ResetPassword() {

  const pageStrings = usePageStrings();
  const [rsetPasswordAction, setResetPasswordAction] = useState(ActionStatus.idle);
  const [tokenValidationAction, setTokenValidationAction] = useState(ActionStatus.idle);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordChecks, setPasswordChecks] = useState({
    length: false,
    upper: false,
    lower: false,
    number: false,
    special: false,
  });  

  useEffect(() => {
    if(tokenValidationAction === ActionStatus.idle)
      {
    const urlObj = new URL(window.location.href);
    const params = new URLSearchParams(urlObj.search);

    const token = params.get("resetCode") || "";
    const userId = params.get("userId") || "";

    if (token && userId) {
      authenticationService.validateResetPasswordToken({ userId, resetCode: token })
        .then((result: Result<string>) => {
          if (!result.isSuccess || result.value !== 'True') {
            setErrorMessage(pageStrings.resetPasswordPage.error_passwordLinkExpired);
            setTokenValidationAction(ActionStatus.failed);
          }
          else {
            setTokenValidationAction(ActionStatus.succeeded);
          }
        })
        .catch((reason) => {
          setErrorMessage(reason.message || pageStrings.general.error_Unexpected);
          setTokenValidationAction(ActionStatus.failed);
          appLogService.error('ResetPassword.tsx', 'useEffect', 'Verify Token', reason);
        });
    } else {
      setErrorMessage(pageStrings.general.error_Unexpected);
      setTokenValidationAction(ActionStatus.failed);
      appLogService.error('ResetPassword.tsx', 'useEffect', 'Verify Token', "Token or User Id not found");
    }
  }
  }, []);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;
    setPassword(value);
    // Update passwordChecks based on the new password value
    setPasswordChecks({
      length: value.length >= 12,
      upper: /[A-Z]/.test(value),
      lower: /[a-z]/.test(value),
      number: /[0-9]/.test(value),
      special: /[!@#$%^&*]/.test(value),
    });
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    const urlObj = new URL(window.location.href);
    const params = new URLSearchParams(urlObj.search);

    const userId = params.get("userId") || "";
    const token = params.get("resetCode") || "";

      authenticationService.resetPassword({
          userId: userId,
          resetCode: token,
          newPassword: password
      }).then((result: Result<string>) => {
        if (result.isSuccess) {
            setResetPasswordAction(ActionStatus.succeeded);
        }
        else {
            setResetPasswordAction(ActionStatus.failed);
        }
      }).catch((reason) => {
          setErrorMessage(reason.message || 'Failed to reset password');
          setResetPasswordAction(ActionStatus.failed);
          appLogService.fatal('Resetpassword.tsx', 'handleSubmit', 'Send', reason);
      });
  };

  const handleReroute = () => {
    window.location.href = '/authentication/credentials/forgot';
  };

  const getColor = (check: boolean) => (check ? "green" : "red");

  return rsetPasswordAction === ActionStatus.succeeded ? (
      <Navigate to="/authentication/signin"></Navigate>
) : (
      <div className={styles['ResetPassword']}>
              <header>
                  <h1 className={styles['ResetPassword-heading1']}>
                      <Text fontStyle='headline-small' value={pageStrings.resetPasswordPage.heading_resetPassword}></Text>
                  </h1>
              </header>

        {errorMessage && (
          <div className={styles["ResetPassword-errorMessage"]}>
            <BannerMessage
              messageType="error"
              message={errorMessage}
            ></BannerMessage>
          </div>
        )}

          <div className={styles["ResetPassword-content"]}>
            {tokenValidationAction === ActionStatus.idle && (
              <Text fontStyle='label-medium' value={pageStrings.resetPasswordPage.text_validatingToken}></Text>
            )}
            {tokenValidationAction === ActionStatus.failed && (
              <div className={styles['ResetPassword-actions']}>
                <FilledTonalButton type="button" value={pageStrings.resetPasswordPage.button_clickHere_label} onClick={handleReroute} ></FilledTonalButton>
            </div>
            )}            
          </div>

      {tokenValidationAction === ActionStatus.succeeded && (
          <Form onSubmit={handleSubmit} className={styles['ResetPassword']}>
        <div className={styles["ResetPassword-content"]}>
          <OutlinedTextField
            label={pageStrings.resetPasswordPage.formItem_password_label}
            name="password"
            placeholder={pageStrings.resetPasswordPage.formItem_password_placeholder}
            required
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />

          <OutlinedTextField
            label={pageStrings.resetPasswordPage.formItem_confirmPassword_label}
            name="confirmPassword"
            placeholder={pageStrings.resetPasswordPage.formItem_confirmPassword_placeholder}
            required
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />

          <ul>
            <li>
              <span style={{ color: getColor(passwordChecks.length) }}>
                <Icon ariaHidden name="circle" size={18}></Icon>
              </span>
              <Text fontStyle='label-medium' value={pageStrings.resetPasswordPage.text_passwordRequirements_minimumCharacters}></Text>
            </li>
            <li>
              <span style={{ color: getColor(passwordChecks.upper) }}>
                <Icon ariaHidden name="circle" size={18}></Icon>
              </span>
              <Text fontStyle='label-medium' value={pageStrings.resetPasswordPage.text_passwordRequirements_upperCase}></Text>
            </li>
            <li>
              <span style={{ color: getColor(passwordChecks.lower) }}>
                <Icon ariaHidden name="circle" size={18}></Icon>
              </span>
              <Text fontStyle='label-medium' value={pageStrings.resetPasswordPage.text_passwordRequirements_lowerCase}></Text>
            </li>
            <li>
              <span style={{ color: getColor(passwordChecks.number) }}>
                <Icon ariaHidden name="circle" size={18}></Icon>
              </span>
              <Text fontStyle='label-medium' value={pageStrings.resetPasswordPage.text_passwordRequirements_number}></Text>
            </li>
            <li>
              <span style={{ color: getColor(passwordChecks.special) }}>
                <Icon ariaHidden name="circle" size={18}></Icon>
              </span>
              <Text fontStyle='label-medium' value={pageStrings.resetPasswordPage.text_passwordRequirements_specialCharacters}></Text>
            </li>
          </ul>

          <div className={styles["ResetPassword-actions"]}>
            <FilledButton type="submit" value={pageStrings.resetPasswordPage.button_resetPassword_label}></FilledButton>
          </div>
        </div>
      </Form>
      )}
    </div>
  );
}

export default ResetPassword;
