import React from 'react';
import PropTypes from 'prop-types';
import { OutlinedTextField } from '../../../common/components/material';
import styles from './ChangePassword.module.scss';
import { PasswordRule } from '../../../common/features/password/PasswordRule';
import { BannerMessage } from '../../../common/components';
import { usePageStrings } from '../../../common/hooks';

interface IComponentProperties {
  formValues: {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    errorMessage: string;
  };
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function ChangePassword(props: IComponentProperties) {
  const { formValues, onInputChange } = props;
  const pageStrings = usePageStrings();

  // List of password requirements
  const passwordRequirements = [
    {
      label: pageStrings.changePassword.text_passwordRequirements_minimumCharacters,
      validation: '.{12,}',
    },
    {
      label: pageStrings.changePassword.text_passwordRequirements_upperCase,
      validation: '[A-Z]',
    },
    {
      label: pageStrings.changePassword.text_passwordRequirements_lowerCase,
      validation: '[a-z]',
    },
    {
      label: pageStrings.changePassword.text_passwordRequirements_number,
      validation: '[0-9]',
    },
    {
      label: pageStrings.changePassword.text_passwordRequirements_specialCharacters,
      validation: '[!@#$%^&*]',
    },
  ];

  return (
    <div className={styles['ChangePassword']}>
      {formValues.errorMessage && (
        <div className={styles['ChangePassword-errorMessage']}>
          <BannerMessage messageType="error" message={formValues.errorMessage} />
        </div>
      )}

      <div className={styles['ChangePassword-content']}>
        <OutlinedTextField
          label={pageStrings.changePassword.formItem_currentPassword_label}
          name="currentPassword"
          placeholder={pageStrings.changePassword.formItem_currentPassword_ariaLabel}
          required
          type="password"
          value={formValues.currentPassword}
          onChange={onInputChange}
        />

        <OutlinedTextField
          label={pageStrings.changePassword.formItem_newPassword_label}
          name="newPassword"
          placeholder={pageStrings.changePassword.formItem_newPassword_ariaLabel}
          required
          type="password"
          value={formValues.newPassword}
          onChange={onInputChange}
        />

        <OutlinedTextField
          label={pageStrings.changePassword.formItem_confirmNewPassword_label}
          name="confirmNewPassword"
          placeholder={pageStrings.changePassword.formItem_confirmNewPassword_ariaLabel}
          required
          type="password"
          value={formValues.confirmNewPassword}
          onChange={onInputChange}
        />

        <ul>
          {passwordRequirements.map((requirement, index) => (
            <li key={index}>
              <PasswordRule
                password={formValues.newPassword}
                label={requirement.label}
                validation={requirement.validation}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

ChangePassword.propTypes = {
  formValues: PropTypes.shape({
    currentPassword: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
    confirmNewPassword: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
  }).isRequired,
  onInputChange: PropTypes.func.isRequired,
};