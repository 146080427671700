import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { appLogService } from '../../../store/slices/appLog';
import { sessionStorageService } from '../../../common/services/sessionStorage.service';
import { setAuthorizationToken, setUserPermissions } from '../../../store/slices/authorization';
import { Result, useAppDispatch } from '../../../store';
import { lms3Service } from '../../../store/slices/lms3';
import { authenticationService } from '../../../store/slices/authentication';
import { SignInWithPasswordResponse } from '../../../viewModels/authentication/signInWithPasswordResponse';
import urlService from '../../../common/services/url.service';

// Note: because of the way the Azure SSO works, we need to use the AzureCallback component to handle the redirect from Azure SSO.
export function AzureCallback() {
//   const { instance } = useMsal();
//   const navigate = useNavigate();

//   const dispatch = useAppDispatch();

//   useEffect(() => {
    
//     instance.handleRedirectPromise()
//     .then((response) => {
//         console.log("AzureCallback");
//         if(!response) {
//             appLogService.fatal('AzureCallback.tsx', 'useEffect', 'SSO', 'No response from Azure SSO');
//             return;
//         }

//         const token = response.toString()

//         authenticationService.azureSignIn({ token: token })
//         .then((result: Result<SignInWithPasswordResponse>) => {
    
//             if (result.isSuccess) {
//                 dispatch(setAuthorizationToken(result.value.token));
//                 dispatch(setUserPermissions(result.value.permissions));                     
//                 sessionStorageService.setAuthorizationToken(result.value.token);
//                 sessionStorageService.setUserPermissions(result.value.permissions);                
                
//                 lms3Service.setLms4Cookie().then( ()=> {
//                     window.location.assign(urlService.buildLegacyAppUrl(''));
//                 }).catch(error => {
//                     appLogService.fatal('AzureCallback.tsx', 'setLms4Cookie', 'AzureCallback', JSON.stringify(error) ?? '');
//                 });    
//             } 
//             else{
//                 window.location.assign("/authentication/signIn");
//             }
//         }).catch((reason) => {
//             appLogService.fatal('AzureCallback.tsx', 'azureSignIn', 'AzureCallback', JSON.stringify(reason) ?? '');
//         });       
//     })
//     .catch((error) => {
//         appLogService.fatal('AzureCallback.tsx', 'handleRedirectPromise', 'SSO', JSON.stringify(error) ?? '');
//     });
//   }, [instance, navigate]);

  return (
    <div>
      <h1>Processing Azure SSO Redirect...</h1>
    </div>
  );
};