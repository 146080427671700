import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { configurationService } from "./configuration.service";
import { IAuthentication, IConfigurationState } from "./configuration.types";

import { Configuration } from '../../../viewModels/application/configuration';
import { BasicConfiguration } from '../../../viewModels/application/basicConfiguration';
import PageStrings from "../../../viewModels/pageStrings/pageStrings";
import { ActionStatus } from "../../config";

const initialState: IConfigurationState = {
    error: null,
    status: ActionStatus.idle,
    authentication: {
        adfs_IsEnabled: false,
        azure_IsEnabled: false,
        okta_isEnabled: false,
        usernameAndPassword_isEnabled: false,
        azure_ClientId: "",
        azure_TenantId: "",
        azure_RedirectUri: ""
    },
    branding: {
        companyLogo: "",
        companyName: "",
        keyColor_primary: "",
        keyColor_secondary: "",
    },
    pages: {
        calendar_IsDisplayed: false,
        history_IsDisplayed: false,
        library_IsDisplayed: false,
        users_IsDisplayed: false
    },
    accountSelfCreation:{
        isEnabled:false,
        formConfiguration_Department:0,
        formConfiguration_Location:0,
        formConfiguration_PracticeArea:0,
        formConfiguration_Telephone:0,
        formConfiguration_Title:0,
        formConfiguration_UserProfile:0,
        formConfiguration_Username:0,

    }
};

const pageStrings = new PageStrings();

export const getConfiguration = createAsyncThunk("application/configuration", async () => {
    const response = await configurationService.getConfiguration();
    return response;
});

export const getConfigurationBasic = createAsyncThunk("application/configuration/basic", async () => {
    const response = await configurationService.getConfigurationBasic();
    return response;
});

export const configurationSlice = createSlice({
    name: 'configuration',
    initialState,
    reducers: {

        authenticationSettingsChanged: (state: IConfigurationState, action: PayloadAction<IAuthentication>) => {
            state.authentication = action.payload;
        }

    },
    extraReducers: (builder) => {

        builder.addCase(getConfigurationBasic.pending, (state: IConfigurationState) => {
            state.status = ActionStatus.loading;
        });

        builder.addCase(getConfigurationBasic.rejected, (state: IConfigurationState) => {
            state.status = ActionStatus.failed;
            state.error = pageStrings.general.error_Unexpected;
        });

        builder.addCase(getConfigurationBasic.fulfilled, (state: IConfigurationState, action: PayloadAction<BasicConfiguration>) => {
            state.status = ActionStatus.succeeded;

            let authentication = action.payload.authentication;

            state.branding = action.payload.branding;

            state.authentication = {
                adfs_IsEnabled: authentication.adfs_IsEnabled,
                azure_IsEnabled: authentication.azure_IsEnabled,
                okta_isEnabled: authentication.okta_isEnabled,
                usernameAndPassword_isEnabled: authentication.usernameAndPassword_isEnabled,
                azure_ClientId: authentication.azure_ClientId,
                azure_TenantId: authentication.azure_TenantId,
                azure_RedirectUri: authentication.azure_RedirectUri
            };
            
            let accountSelfCreation = action.payload.accountSelfCreation;

            state.accountSelfCreation={
                isEnabled: accountSelfCreation.isEnabled,
                formConfiguration_Username: accountSelfCreation.formConfiguration_Username,
                formConfiguration_Telephone: accountSelfCreation.formConfiguration_Telephone,
                formConfiguration_Location: accountSelfCreation.formConfiguration_Location,
                formConfiguration_Title: accountSelfCreation.formConfiguration_Title,
                formConfiguration_Department: accountSelfCreation.formConfiguration_Department,
                formConfiguration_PracticeArea: accountSelfCreation.formConfiguration_PracticeArea,
                formConfiguration_UserProfile: accountSelfCreation.formConfiguration_UserProfile

            }
        });

        builder.addCase(getConfiguration.pending, (state: IConfigurationState) => {
            state.status = ActionStatus.loading;
        });

        builder.addCase(getConfiguration.rejected, (state: IConfigurationState) => {
            state.status = ActionStatus.failed;
            state.error = pageStrings.general.error_Unexpected;
        });

        builder.addCase(getConfiguration.fulfilled, (state: IConfigurationState, action: PayloadAction<Configuration>) => {
            state.status = ActionStatus.succeeded;

            let authentication = action.payload.authentication;

            state.authentication = {
                adfs_IsEnabled: authentication.adfs_IsEnabled,
                azure_IsEnabled: authentication.azure_IsEnabled,
                okta_isEnabled: authentication.okta_isEnabled,
                usernameAndPassword_isEnabled: authentication.usernameAndPassword_isEnabled,
                azure_ClientId: authentication.azure_ClientId,
                azure_TenantId: authentication.azure_TenantId,
                azure_RedirectUri: authentication.azure_RedirectUri
            };
            
            state.branding = action.payload.branding;
            
            state.pages = action.payload.pages;

        });

    }


});

export const {
    authenticationSettingsChanged
} = configurationSlice.actions;

export default configurationSlice.reducer;