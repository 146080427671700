import { combineReducers } from "redux";
import authenticationReducer from "./slices/authentication/authentication.slice";
import authorizationReducer from './slices/authorization/authorization.slice';
import layoutReducer from "./slices/layout/layout.slice";
import configurationReducer from "./slices/configuration/configuration.slice";
import snackbarReducer from "./slices/snackbar/snackbar.slice";
import departmentReducer from "./slices/department/department.slice";
import  userProfileReducer  from "./slices/userprofile/userprofile.slice";
import jobTitleReducer from "./slices/jobTitle/jobTitle.slice";
import practiceAreaReducer  from "./slices/practiceArea/practiceArea.slice";
import locationReducer from "./slices/location/location.slice";


export default combineReducers({
    authentication: authenticationReducer,
    authorization: authorizationReducer,
    configuration: configurationReducer,
    department:departmentReducer,
    layout: layoutReducer,
    location:locationReducer,
    practiceArea:practiceAreaReducer,
    jobTitle:jobTitleReducer,
    snackbar:snackbarReducer,
    userProfile:userProfileReducer,
});