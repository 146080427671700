import {BaseService, Result} from "../../config";


import { RequestResetPasswordRequest } from "../../../viewModels/authentication/requestResetPasswordRequest";
import { ResetPasswordRequest } from "../../../viewModels/authentication/resetPasswordRequest";
import { SignInWithPasswordRequest } from "../../../viewModels/authentication/signInWithPasswordRequest";
import { ChangePasswordRequest } from "../../../viewModels/authentication/changePasswordRequest";
import { SignInWithPasswordResponse } from "../../../viewModels/authentication/signInWithPasswordResponse";
import { SignInWithAzureRequest } from "../../../viewModels/authentication/signInWithAzureRequest";
import { AssumeIdentityRequest } from "../../../viewModels/authentication/assumeIdentityRequest";
import { ValidateResetPasswordTokenRequest } from "../../../viewModels/authentication/validateResetPasswordTokenRequest";
import { CreateAccount } from "../../../viewModels/authentication/createAccount";

class AuthenticationService extends BaseService {

    public constructor() {
        super('authentication');
    }

    public async signIn(request: SignInWithPasswordRequest): Promise<Result<SignInWithPasswordResponse>>{
        return await this.httpPost('signin', request);
    }

    public async azureSignIn(request: SignInWithAzureRequest ): Promise<Result<SignInWithPasswordResponse>>{
        return await this.httpPost('azure/signin', request);
    }

    public async signOut(): Promise<Result<string>> {
        return await this.httpPost('signout');
    }

    public async createAccount(request: CreateAccount): Promise<Result<string>> {
        return await this.httpPost('createaccount', request);
    }

    public async resetPasswordRequest(request: RequestResetPasswordRequest): Promise<Result<string>> {
        return await this.httpPost('password/reset/request', request);
    }

    public async resetPassword(request: ResetPasswordRequest): Promise<Result<string>> {
        return await this.httpPost('password/reset', request);
    }

    public async validateResetPasswordToken(request: ValidateResetPasswordTokenRequest): Promise<Result<string>> {
        return await this.httpPost('password/reset/validate', request);
    }

    public async assumeIdentity(request: AssumeIdentityRequest): Promise<Result<SignInWithPasswordResponse>> {
        return await this.httpPost('identity/assume', request);
    }

    public async revertIdentity(): Promise<Result<SignInWithPasswordResponse>> {
        return await this.httpPost(`identity/revert`);
    }

    public async changePassword(request: ChangePasswordRequest): Promise<Result<string>> {
        return await this.httpPost('password/change', request);
    }
}

const authenticationService = new AuthenticationService();

export {authenticationService};