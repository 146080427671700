import { useNavigate } from "react-router-dom";
import { usePageStrings } from "../../../common/hooks";
import { FilledTonalButton, Text } from '../../../common/components';
import styles from "./SignOut.module.scss";

export function SignOut(){

    const pageStrings = usePageStrings();
    const navigate = useNavigate();

    function handleReroute(){
        navigate("/authentication/signin");
    }

    return (
        <div className={styles['SignOut']}>

            <header>

                <h1 className={styles['SignOut-heading1']}>
                    <Text fontStyle="headline-large" value={pageStrings.signOutPage.heading_signOut}></Text>
                </h1>

            </header>

            <span className={styles['SignOut-Body']}>
                <Text fontStyle="body-large" value={pageStrings.signOutPage.text_signOut}></Text>
            </span>
            <div className={styles['SignOut-actions']}>
                <FilledTonalButton type="button" value={pageStrings.signOutPage.button_signIn_label} onClick={handleReroute} ></FilledTonalButton>
            </div>
        </div>
   );
  
}
