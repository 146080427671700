import { Snackbar } from "../../../components";
import { useAppDispatch, useAppSelector } from "../../../../store";

export default function SnackbarManager() {
  const dispatch = useAppDispatch();

  const snackbar = useAppSelector((state) => state.snackbar);

  return (
    <>
      {snackbar.visibleItem && (
        <Snackbar
          actionText={snackbar.visibleItem?.actionText}
          action={snackbar.visibleItem?.action }
          supportingText={snackbar.visibleItem?.message}
          configuration="single-line"
        ></Snackbar>
      )}
    </>
  );
}
