
import {Icon} from '../../../components/material';

import styles from './NavigationDrawerItem.module.scss';

interface IComponentProperties {
    iconName: string,
    labelText: string,
    linkUrl: string,
    isActive: boolean,
}

export function NavigationDrawerItem (props: IComponentProperties) {

    return (
        <li className={`${styles["NavigationDrawerItem"]} ${props.isActive? styles['is-active']: ''}`} role='tab'>
            <span className={styles["NavigationDrawerItem-background"]}></span>
            <span className={styles["NavigationDrawerItem-stateLayer"]}></span>
            <a href={props.linkUrl} className={styles["NavigationDrawerItem-content"]}>
                <span className={styles["NavigationDrawerItem-icon"]}>
                    <Icon name={props.iconName} size={24} ariaHidden={true} isFilled={props.isActive}></Icon>
                </span>
                <span className={styles["NavigationDrawerItem-labelText"]}>
                    {props.labelText}
                </span>
            </a>
            <div className={styles["NavigationDrawerItem-outline"]}></div>
        </li>
    );
}