import { useState } from "react";

import { Divider, FilledButton, Form, Radio, RadioGroup } from "../../../../common/components";
import { AuthenticationDetailPaneSSOProviders } from "./authenticationDetailPaneSSOProviders/AuthenticationDetailPaneSSOProviders";
import { Authentication } from "../../../../viewModels/configuration/settings/authentication";

import styles from './AuthenticationDetailPane.module.scss';
import { useActionChecker, usePageStrings } from "../../../../common/hooks";

const authenticationMethod_UsernameAndPassword:string = "UsernameAndPassword";
const authenticationMethod_SingleSignOn:string = "SingleSignOn";
const authenticationMethod_Both:string = "Both";

interface IComponentProperties {
  onSubmit: Function,
  authenticationSettings: Authentication
};


type ISsoProviderChangedArg = {
    adfs_isEnabled: boolean,
    azure_isEnabled: boolean,
    okta_isEnabled: boolean,
}

export function AuthenticationDetailPane(props: IComponentProperties) {


    const pageStrings = usePageStrings();
    const actionChecker = useActionChecker();
    
    const [authenticationMethodSelected, setAuthenticationMethodSelected] = useState(authenticationMethodSelectedInitialValue(props.authenticationSettings));

    const [ssoProviderSelected, setSsoProSelected] = useState({
        adfs_isEnabled: props.authenticationSettings.adfs_isEnabled,
        azure_isEnabled: props.authenticationSettings.azure_isEnabled,
        okta_isEnabled: props.authenticationSettings.okta_isEnabled,
    });
    
    const canEdit = actionChecker.canDoAction("settings.canEdit.policy");

    function handleAuthenticationMethodChanged(selectedValue:any) {
        setAuthenticationMethodSelected(selectedValue);    
    }

    function handleFormSubmit (formData: any){

        const usernameAndPassword_isEnabled = 
            authenticationMethodSelected === authenticationMethod_UsernameAndPassword ||
            authenticationMethodSelected === authenticationMethod_Both;

        const isSsoEnabled = 
            authenticationMethodSelected === authenticationMethod_SingleSignOn ||
            authenticationMethodSelected === authenticationMethod_Both;


        let authentication: Authentication = {
            adfs_clientId: formData.adfs_clientId || '',
            adfs_isEnabled: ssoProviderSelected.adfs_isEnabled && isSsoEnabled,
            adfs_loginPropertyName: formData.adfs_loginPropertyName || '',
            adfs_orgUri: formData.adfs_orgUri || '',
            azure_clientId: formData.azure_clientId || '',
            azure_clientSecret: formData.azure_clientSecret || '',
            // azure_clientSecretExpiryDate: new Date(),
            azure_isEnabled: ssoProviderSelected.azure_isEnabled && isSsoEnabled,
            azure_loginNameProperty: formData.azure_loginNameProperty || '',
            azure_tenantName: formData.azure_tenantName || '',
            okta_accountName: formData.okta_accountName || '',
            okta_appId: formData.okta_appId || '',
            okta_appSecret: formData.okta_appSecret || '',
            // okta_appSecretExpiryDate: new Date(),
            okta_authorizationServer: formData.okta_authorizationServer || '',
            okta_isEnabled: ssoProviderSelected.okta_isEnabled && isSsoEnabled,
            okta_loginNameProperty: formData.okta_loginNameProperty || '',
            usernameAndPassword_isEnabled: usernameAndPassword_isEnabled
        };
        props.onSubmit(authentication);

    }

    return (
        
        <Form onSubmit={handleFormSubmit} className={styles['AuthenticationDetailPane']}>

        <div className={styles['AuthenticationDetailPane-authenticationMethods']}>

            <RadioGroup label={pageStrings.authenicationPage.radio_authenticationMethods_label} >

            <Radio labelText={pageStrings.authenicationPage.radio_authenticationMethods_usernameAndPassword_option} name="authenticationMethod" 
                checked={authenticationMethodSelected === authenticationMethod_UsernameAndPassword } 
                value={authenticationMethod_UsernameAndPassword} 
                onChanged={handleAuthenticationMethodChanged}
            ></Radio>

            <Radio labelText={pageStrings.authenicationPage.radio_authenticationMethods_singleSignOn_option} name="authenticationMethod" 
                checked={authenticationMethodSelected === authenticationMethod_SingleSignOn } 
                value={authenticationMethod_SingleSignOn} 
                onChanged={handleAuthenticationMethodChanged}
            ></Radio>

            <Radio labelText={pageStrings.authenicationPage.radio_authenticationMethods_both_option} name="authenticationMethod" 
                checked={authenticationMethodSelected === authenticationMethod_Both } 
                value={authenticationMethod_Both} 
                onChanged={handleAuthenticationMethodChanged}
            ></Radio>
            </RadioGroup>  

        </div>

        {authenticationMethodSelected !== authenticationMethod_UsernameAndPassword && (
        <>
            <Divider></Divider>
            <AuthenticationDetailPaneSSOProviders authenticationSettings={props.authenticationSettings}
                ssoProviderChanged={(e: ISsoProviderChangedArg) => setSsoProSelected(e)}
            ></AuthenticationDetailPaneSSOProviders>
        </>
        )}

        <div className={styles['AuthenticationDetailPane-blank']}></div>

        {canEdit &&
            <>
                <Divider></Divider>
        
                <div className={styles['AuthenticationDetailPane-actions']}>
                    
                    <div>
                        <FilledButton type={"submit"} value={"Save"} iconName="save"></FilledButton>
                    </div>

                </div>
            </>
        }        

        </Form>
    );
}

function authenticationMethodSelectedInitialValue(authenticationSettings: Authentication){
    console.log(authenticationSettings)
  const isUsernameAndPaswordEnabled = authenticationSettings.usernameAndPassword_isEnabled === true;

  const isSsoEnabled =
      authenticationSettings.adfs_isEnabled === true ||
      authenticationSettings.azure_isEnabled === true ||
      authenticationSettings.okta_isEnabled === true;

  if (isUsernameAndPaswordEnabled && isSsoEnabled){
    
    return authenticationMethod_Both;

  } else if (isSsoEnabled) {
    
    return authenticationMethod_SingleSignOn;

  } else if (isUsernameAndPaswordEnabled){
    
    return authenticationMethod_UsernameAndPassword; 

  }

  return '';

}



