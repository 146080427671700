import { useState } from "react";
import { UserBasic } from "../../../viewModels/user/userBasic";
import { UserListItem } from "./UserListItem";

interface IComponentProperties {
    users: UserBasic[],
    onItemClicked?: (user: UserBasic) => void,
}

export function UserList (props: IComponentProperties){
    


    function handleItemClicked (user: UserBasic){
        if (props.onItemClicked){
            props.onItemClicked(user);
        }
    }

    function  maplistItems(){    

        const listItems = props.users?.map((user: UserBasic, i: number) => {
            return (
                <li  key={i}>            
                    <UserListItem 
                        userFullname={user.fullName} 
                        userIdentifier={user.userId}
                        userEmailAddress={user.email}
                        userInitials={user.firstName.substring(0,1) + user.lastName.substring(0,1)}
                        listItemClicked={() => handleItemClicked(user)}></UserListItem>
                </li>
            )
        });
        return listItems;
    }

    return (
        <ul>
            {maplistItems()}
        </ul>
    );
}

