import {SignalR} from "../../config";

const authenticationSettingsChanged = 'AuthenticationSettingsChanged';

class ConfigurationHub extends SignalR {

    private static instance: ConfigurationHub;

    private constructor() {
        super("configuration");
    }

    public static getInstance(): ConfigurationHub {
        
        if (!ConfigurationHub.instance){

            ConfigurationHub.instance = new ConfigurationHub();

            // ConfigurationHub.instance.start();

        }

        return ConfigurationHub.instance;
    }

    public OnAuthenticationSettingsChanged(callback: (authenticationSettings: any) => void) {
        this.on(authenticationSettingsChanged, callback);
    }

}

const configurationHub = ConfigurationHub.getInstance();

export { configurationHub };