import { NavigationRailItem } from './NavigationRailItem';

import styles from './NavigationRail.module.scss';
import {useNavigationItems} from '../../../hooks';

interface IComponentProperties {    
    scollbarVisible:boolean
}
export function NavigationRail (props: IComponentProperties){

    const scollbarVisibleStyle = props.scollbarVisible ? styles['with-scroll']: ''; 

    const navigationItems = useNavigationItems();

    const NavigationRailItems = navigationItems.map(item => {
        return  <NavigationRailItem key={item.id} labelText={item.labelText} iconName={item.iconName} 
            linkUrl={item.linkUrl} isActive={item.id === navigationItems[0].id}></NavigationRailItem>
    });

    return (
        <nav aria-label="Primary" className={`${styles['NavigationRail']} ${scollbarVisibleStyle}`}>
            <div className={styles["NavigationRail-background"]}></div>
            <div className={styles["NavigationRail-content"]}>
                <ul>
                    {NavigationRailItems}
                </ul>
            </div>
        </nav>
    );

}