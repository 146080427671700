import { useEffect, useState } from 'react';
import { Text, FilledTonalButton, Dialog, FilledTextField } from '../../../../../common/components';
import { closeDialog, openDialog } from '../../../../../common/components/material/dialog/Dialog';
import { UserList } from '../../../../../common/features/users/UserList';
import { sessionStorageService } from '../../../../../common/services/sessionStorage.service';
import { Result, RootState, useAppDispatch, useAppSelector } from '../../../../../store';
import { authenticationService } from '../../../../../store/slices/authentication';
import { setAssumingIdentity, setAuthorizationToken, setUserPermissions } from '../../../../../store/slices/authorization';
import { lms3Service } from '../../../../../store/slices/lms3';

import styles from './Identity.module.scss';
import { UserBasic } from '../../../../../viewModels/user/userBasic';
import { userAccountService } from '../../../../../store/slices/profile/useraccount.service';
import { SignInWithPasswordResponse } from '../../../../../viewModels/authentication/signInWithPasswordResponse';
import { appLogService } from '../../../../../store/slices/appLog';
import { usePageStrings } from '../../../../../common/hooks';

interface IComponentProperties {
}
export function Identity (props: IComponentProperties) {

    const dispatch = useAppDispatch();

    const authorization = useAppSelector((state: RootState) => state.authorization);
    const pageStrings = usePageStrings();

    const [userInformation, setUserInformation] = useState(getData);
    const [searchFilter, setSearchFilter] = useState('');
    const [filteredUsers, setFilteredUsers] = useState<UserBasic[]>([]);

    function getData():UserBasic[] {
   
        let r:UserBasic[] = [];
    
        userAccountService.GetUserBasicInfo().then((basicInfo) => {
            r.push(...basicInfo.value);
        })
        .catch(
        (error) => {
            
        });
        return r;
    }

    const assumedIdentityDialogId = 'assumed-identity-dialog';    

    function handleAssumeIdentity(user: UserBasic) {
        const learnerId = user.userId;

        authenticationService.assumeIdentity({ learnerId: learnerId }).then((response: Result<SignInWithPasswordResponse>) => {
            if (response.isSuccess) {

                    dispatch(setAuthorizationToken(response.value.token));
                    dispatch(setUserPermissions(response.value.permissions)); 

                    sessionStorageService.setAuthorizationToken(response.value.token);
                    sessionStorageService.setUserPermissions(response.value.permissions); 

                    dispatch(setAssumingIdentity(response.value.token));

                    lms3Service.setLms4Cookie().then( ()=>{

                    }).catch(error => {
                        appLogService.fatal('Identity.tsx', 'handleAssumeIdentity', 'assumeIdentity', error??'');
                    });    
            }
        });
        closeDialog(assumedIdentityDialogId);
    }

    function handleRevertIdentity() {
        authenticationService.revertIdentity().then((response: Result<SignInWithPasswordResponse>) => {
            if (response.isSuccess){

                console.log(response.value.token)
                dispatch(setAuthorizationToken(response.value.token));
                dispatch(setUserPermissions(response.value.permissions)); 
                dispatch(setAssumingIdentity(response.value.token));

                sessionStorageService.setAuthorizationToken(response.value.token);
                sessionStorageService.setUserPermissions(response.value.permissions); 

                lms3Service.setLms4Cookie().then( ()=>{       
                }).catch(error => {
                    appLogService.fatal('Identity.tsx', 'handleRevertIdentity', 'revertIdentity', error??'');
                });    
            }
        });
    }

    const handleSearchFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let filteredUserList = [];
        filteredUserList.push(...userInformation.filter((user) => user.fullName.toLowerCase().indexOf(value.toLowerCase()) > -1 ));
        setSearchFilter(value);   
        setFilteredUsers(filteredUserList.sort((a,b) => (a.fullName < b.fullName)?-1:1))
      };

    return (
        <section className={styles['Identity']}>
            <h2><Text value={pageStrings.myProfilePage.heading_identity} fontStyle='title-medium'></Text></h2>
            <p><Text value={pageStrings.myProfilePage.text_assumeIdentity} fontStyle='body-medium'></Text></p>
            <div>
                {!authorization.isAssumingIdentity && (
                    <FilledTonalButton buttonId="identity-assumebutton" type='button' value={pageStrings.myProfilePage.button_assumeIdentity_label} onClick={() => {openDialog(assumedIdentityDialogId); setFilteredUsers(userInformation);}}></FilledTonalButton>
                )}
                {authorization.isAssumingIdentity && (
                    <FilledTonalButton buttonId="identity-revertbutton" type='button' value={pageStrings.myProfilePage.button_revertIdentity_label}onClick={handleRevertIdentity}></FilledTonalButton>
                )}
            </div>
            <Dialog 
                id={assumedIdentityDialogId} 
                headline={pageStrings.myProfilePage.heading_selectUser} 
                className={styles['Identity-selectUserDialog']}>
                <span className={styles['Identity-selectUserDialogSearch']}>
                    <FilledTextField name="userFilter"
                            type='search'
                            label=''
                            placeholder={pageStrings.myProfilePage.button_search_placeholder} 
                            value={searchFilter}
                            onInput={handleSearchFilterChange}                        
                            >
                    </FilledTextField>
                </span>
                <UserList users={filteredUsers} onItemClicked={handleAssumeIdentity}></UserList>
            </Dialog>
        </section>
    );
}
