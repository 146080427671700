import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { usePageStrings } from "../../../common/hooks";
import { ActionStatus, AppDispatch, RootState, useAppDispatch, useAppSelector } from "../../../store";
import { appLogService } from "../../../store/slices/appLog";
import { lms3SetLms4Cookie, signInWithAzure, signInWithForm } from "../../../store/slices/authorization";
import { BannerMessage, Divider, FilledTonalButton, Text } from "../../../common/components";
import SignInUserAndPassword from "./signInUserAndPassword/SignInUserAndPassword";
import styles from "./SignIn.module.scss";
import urlService from "../../../common/services/url.service";
import { createMsalInstance, loginRequest } from "../../../authAzureConfig";
import { Authentication } from "../../../viewModels/application/configuration/authentication";



export function SignIn() {

    const dispatch = useAppDispatch();

    const pageStrings = usePageStrings();
    const [searchParams] = useSearchParams();

    const authorization = useAppSelector(state => state.authorization);

    const configurationAuthentication = useAppSelector((state: RootState) => state.configuration.authentication);

    const isUserAndPasswordEnabled = configurationAuthentication.usernameAndPassword_isEnabled === true;

    const isSsoEnabled =
        configurationAuthentication.adfs_IsEnabled === true ||
        configurationAuthentication.azure_IsEnabled === true ||
        configurationAuthentication.okta_isEnabled === true;

    const refUrl = getRefUrl(searchParams);

    useEffect(() => {

        if (authorization.signInStatus === ActionStatus.idle){

            // Clear all cookies
            document.cookie.split(";").forEach((c) => {
                document.cookie = c
                    .replace(/^ +/, "")
                    .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
            });

            // Clear sessionStorage and localStorage
            sessionStorage.clear();
            localStorage.clear();

            if (!isUserAndPasswordEnabled && isSsoEnabled){

                loginWithSSO(configurationAuthentication, dispatch);

            }

        } else if (authorization.signInStatus === ActionStatus.succeeded){

            if (authorization.signInPropagationStatus === ActionStatus.idle){

                dispatch(lms3SetLms4Cookie());              

            } else if (authorization.signInPropagationStatus === ActionStatus.succeeded) {

                window.location.assign(refUrl);

            }

        }
        
        
    }, [authorization, isUserAndPasswordEnabled, isSsoEnabled, refUrl, configurationAuthentication, dispatch]);

    function handleSingleSignOn () {  
        loginWithSSO(configurationAuthentication, dispatch);
    }

    function handleSignIn(formData: SignInUsernameAndPasswordFormData) {
        dispatch(signInWithForm({
            password: formData.password,
            username: formData.username
        }));
    }    

    return (
        <div className={styles["SignIn"]}>
            <header>
                <h1 className={styles["SignIn-heading1"]}>
                    <Text fontStyle="headline-small" value={pageStrings.signInPage.heading_welcome}></Text>
                </h1>
            </header>

            {authorization.signInErrorMessage && authorization.signInStatus === ActionStatus.failed && (
                <div className={styles["SignIn-errorMessage"]}>
                    <BannerMessage messageType="error" message={authorization.signInErrorMessage}></BannerMessage>
                </div>
            )}
            <div className={styles["SignIn-content-container"]}>
                <div className={styles["SignIn-content"]}>
                    {isUserAndPasswordEnabled && (
                        <SignInUserAndPassword
                            onSubmit={handleSignIn}
                            isSubmitting={authorization.signInStatus === ActionStatus.loading || authorization.signInStatus === ActionStatus.succeeded}
                        ></SignInUserAndPassword>
                    )}

                    {(isUserAndPasswordEnabled && isSsoEnabled) && <Divider></Divider>}

                    {isSsoEnabled && (
                        <FilledTonalButton type={"button"} value={pageStrings.signInPage.button_singleSignOn_label} onClick={handleSingleSignOn}></FilledTonalButton>
                    )}
                </div>
            </div>
        </div>
    );
}

type SignInUsernameAndPasswordFormData = {
    username: string;
    password: string;
};

function getRefUrl (searchParams: URLSearchParams){
    
    let refUrl = searchParams.get("refUrl") ?? urlService.buildLegacyAppUrl("");

    if(!refUrl.includes('tzName=')){

        const joinCharacter = refUrl.includes('?')? '&': '?';

        refUrl = `${refUrl}${joinCharacter}tzName=${encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone)}`;

    }

    return refUrl;
}

function loginWithSSO (configurationAuthentication: Authentication, dispatch: AppDispatch) { 

    if(configurationAuthentication.azure_IsEnabled){    
        createMsalInstance({
            azure_ClientId: configurationAuthentication.azure_ClientId,
            azure_TenantId: configurationAuthentication.azure_TenantId,
            azure_RedirectUri: configurationAuthentication.azure_RedirectUri,
        }).then((instance) => {

            instance.loginPopup(loginRequest).then((response) => {

                dispatch(signInWithAzure({
                    token: response.accessToken
                }));
    
            })
            .catch((error: any) => {
                appLogService.fatal("SignIn.tsx", "handleSingleSignOn", "SSO", JSON.stringify(error) ?? "");
            });
        });
    }
};
