import { useState } from "react";
import { Divider, FilledButton, Form, OutlinedTextField, Radio, RadioGroup, Switch, Text, } from "../../../../common/components";
import { AccountSelfCreation } from "../../../../viewModels/configuration/accountSelfCreation";

import styles from './AccountSelfCreationDetailPane.module.scss';
import { ApprovalRequestEmail } from "../../../../viewModels/configuration/approvalRequestEmail";
import { VisibilityOption } from "../../../../viewModels/configuration/visibilityOption";
import { AccountSelfCreationConfiguration } from "./accountSelfCreationConfiguration/AccountSelfCreationConfiguration";
import { AccountSelfCreationConfigurationNoButton } from "./accountSelfCreationConfigurationNoButton/AccountSelfCreationConfigurationNoButton";
import { usePageStrings } from "../../../../common/hooks";


interface IComponentProperties {
    onSubmit: Function,
    accountSelfCreationSettings: AccountSelfCreation
  };
  
  export function AccountSelfCreationDetailPane (props: IComponentProperties) {
    const pageStrings = usePageStrings();
    const [accountSelfCreationEnabled, setAccountSelfCreationEnabled] = useState(props.accountSelfCreationSettings.isEnabled===true);
    const [approvalRequiredEnabled, setApprovalRequiredEnabled] = useState(props.accountSelfCreationSettings.approvalRequired_IsEnabled===true);    
    const [approvalRequestEmailSelected, setApprovalRequestEmailSelected] = useState(approvalRequestEmailInitialValue(props.accountSelfCreationSettings.approvalRequired_SentTo));    
    const [departmentSelection, setDepartmentSelection] = useState(visibilityOptionInitialValue(props.accountSelfCreationSettings.formConfiguration_Department));
    const [locationSelection, setLocationSelection] = useState(visibilityOptionInitialValue(props.accountSelfCreationSettings.formConfiguration_Location));
    const [practiceAreaSelection, setPracticeAreaSelection] = useState(visibilityOptionInitialValue(props.accountSelfCreationSettings.formConfiguration_PracticeArea));
    const [telephoneSelection, setTelephoneSelection] = useState(visibilityOptionInitialValue(props.accountSelfCreationSettings.formConfiguration_Telephone));
    const [titleSelection, setTitleSelection] = useState(visibilityOptionInitialValue(props.accountSelfCreationSettings.formConfiguration_Title));
    const [userProfileSelection, setUserProfileSelection] = useState(visibilityOptionInitialValue(props.accountSelfCreationSettings.formConfiguration_UserProfile));
    const [usernameSelection, setUsernameSelection] = useState(visibilityOptionInitialValue(props.accountSelfCreationSettings.formConfiguration_Username));
   
    function handleRequestEmailMethodChanged(selectedValue:any) {
        setApprovalRequestEmailSelected(selectedValue);    
    }

    function handleApprovalRequiredClick() {  
        setApprovalRequiredEnabled(!approvalRequiredEnabled);    
        
    }

    function handleAccountSelfCreationClick() {  
        setAccountSelfCreationEnabled(!accountSelfCreationEnabled);  
        if(accountSelfCreationEnabled) 
        {
            setApprovalRequiredEnabled(false); 
        }
    }

    function handleDepartmentSelection(selectedValue:VisibilityOption) {  
        setDepartmentSelection(selectedValue);   
    }

    function handleLocationSelection(selectedValue:VisibilityOption) {  
        setLocationSelection(selectedValue);   
    }

    function handlePracticeAreaSelection(selectedValue:VisibilityOption) {  
        setPracticeAreaSelection(selectedValue);   
    }

    function handleTelephoneSelection(selectedValue:VisibilityOption) {  
        setTelephoneSelection(selectedValue);   
    }

    function handleTitleSelection(selectedValue:VisibilityOption) {  
        setTitleSelection(selectedValue);   
    }

    function handleUsernameSelection(selectedValue:VisibilityOption) {  
        setUsernameSelection(selectedValue);   
    }

    function handleUserProfileSelection(selectedValue:VisibilityOption) {  
        setUserProfileSelection(selectedValue);   
    }

    function handleFormSubmit (formData: AccountSelfCreation) {
        if(accountSelfCreationEnabled)
        {
            formData.isEnabled = accountSelfCreationEnabled;
            formData.approvalRequired_IsEnabled = approvalRequiredEnabled;
            formData.approvalRequired_SentTo = approvalRequestEmailSelected;                            
            formData.approvalRequired_SpecificEmailAddress = props.accountSelfCreationSettings.approvalRequired_SpecificEmailAddress ?? "";
            formData.formConfiguration_Department = departmentSelection;
            formData.formConfiguration_Location = locationSelection;
            formData.formConfiguration_PracticeArea = practiceAreaSelection;
            formData.formConfiguration_Telephone = telephoneSelection;
            formData.formConfiguration_Title = titleSelection;
            formData.formConfiguration_Username =  usernameSelection;
            formData.formConfiguration_UserProfile =  userProfileSelection;

            formData.approvalRequired_SentTo =  parseInt(approvalRequestEmailSelected.toString());
        }
        props.onSubmit(formData);
    }
    
    return (
        <>
        
            <Form onSubmit={handleFormSubmit} className={styles['AccountSelfCreationDetailPane']}>
                <div className={styles['AccountSelfCreationDetailPane-enabledSection']}>
                    <Switch required 
                        name="isEnabled" 
                        label ={pageStrings.accountSelfCreationPage.switch_accountSelfCreation_label} 
                        ariaLabel={pageStrings.accountSelfCreationPage.switch_accountSelfCreation_ariaLabel} 
                        selected = {accountSelfCreationEnabled} 
                        onChanged={handleAccountSelfCreationClick}
                    ></Switch>
                </div>
                <Divider></Divider>
                <div className={styles['AccountSelfCreationDetailPane-approvalRequiredSection']} >
                    <Switch 
                        name="approvalRequired_IsEnabled" 
                        label ={pageStrings.accountSelfCreationPage.switch_approvalRequired_label} 
                        ariaLabel={pageStrings.accountSelfCreationPage.switch_approvalRequired_ariaLabel}
                        selected = {approvalRequiredEnabled}  
                        onChanged={handleApprovalRequiredClick}
                        disabled = {accountSelfCreationEnabled===false}
                    ></Switch>
                    {approvalRequiredEnabled === true  &&
                    < >
                        <RadioGroup label={pageStrings.accountSelfCreationPage.radio_approvalRequestEmail_label}>
                            <Radio labelText={pageStrings.accountSelfCreationPage.radio_approvalRequestEmail_doNotSend_option} name="approvalRequired_SentTo" 
                                checked={approvalRequestEmailSelected.toString() === ApprovalRequestEmail.DontSend.toString()} 
                                value={ApprovalRequestEmail.DontSend.toString()}
                                onChanged={handleRequestEmailMethodChanged}
                            ></Radio>
                            <Radio labelText={pageStrings.accountSelfCreationPage.radio_approvalRequestEmail_SystemAdministrator_option} name="approvalRequired_SentTo" 
                                checked={approvalRequestEmailSelected.toString() === ApprovalRequestEmail.SendToSystemAdministrator.toString() } 
                                value={ApprovalRequestEmail.SendToSystemAdministrator.toString()}
                                onChanged={handleRequestEmailMethodChanged}
                                ></Radio>
                            <Radio labelText={pageStrings.accountSelfCreationPage.radio_approvalRequestEmail_SpecificEmailAddress_option} name="approvalRequired_SentTo" 
                                checked={approvalRequestEmailSelected.toString() === ApprovalRequestEmail.SendToSpecificEmailAddress.toString() } 
                                value={ApprovalRequestEmail.SendToSpecificEmailAddress.toString()} 
                                onChanged={handleRequestEmailMethodChanged}
                                ></Radio>
                        </RadioGroup> 
                        <OutlinedTextField 
                            label={pageStrings.accountSelfCreationPage.formItem_emailAddress_label} 
                            type="email"
                            name="ApprovalRequired_SpecificEmailAddress"          
                            placeholder={pageStrings.accountSelfCreationPage.formItem_emailAddress_label} 
                            value={props.accountSelfCreationSettings.approvalRequired_SpecificEmailAddress ?? ""}
                            disabled = {approvalRequestEmailSelected.toString() !== ApprovalRequestEmail.SendToSpecificEmailAddress.toString()}
                            required = {approvalRequestEmailSelected.toString() !== ApprovalRequestEmail.SendToSpecificEmailAddress.toString()}
                        ></OutlinedTextField> 
                            
                    </>}
                </div>
                <div className={styles['AccountSelfCreationDetailPane-blank']}></div>
                <Divider></Divider>
                <section className={styles['AccountSelfCreationDetailPane-formConfigurationSection']}>
                    <h3>
                        <span className={styles["AccountSelfCreationDetailPane-formConfigurationSection-headerText"]}>{pageStrings.accountSelfCreationPage.heading_formConfiguration}
                            </span>
                    </h3>
                    <div className={styles['AccountSelfCreationDetailPane-formConfigurationOptions']}>
                        <AccountSelfCreationConfigurationNoButton 
                            value={pageStrings.accountSelfCreationPage.switch_approvalRequired_label}  
                            label={pageStrings.accountSelfCreationPage.formItem_firstName_label} 
                        ></AccountSelfCreationConfigurationNoButton>          
                        <AccountSelfCreationConfigurationNoButton 
                            value={pageStrings.accountSelfCreationPage.switch_approvalRequired_label}  
                            label={pageStrings.accountSelfCreationPage.formItem_lastName_label} 
                        ></AccountSelfCreationConfigurationNoButton>          
                        <AccountSelfCreationConfigurationNoButton 
                            value={pageStrings.accountSelfCreationPage.switch_approvalRequired_label}  
                            label={pageStrings.accountSelfCreationPage.formItem_emailAddress_label} 
                        ></AccountSelfCreationConfigurationNoButton>
                        <AccountSelfCreationConfiguration 
                            visibilityOptionValue={usernameSelection} 
                            label={pageStrings.accountSelfCreationPage.formItem_username_label}  
                            disabled={!accountSelfCreationEnabled}                      
                            onChange={handleUsernameSelection}
                        ></AccountSelfCreationConfiguration>
                        <AccountSelfCreationConfigurationNoButton 
                            value={pageStrings.accountSelfCreationPage.switch_approvalRequired_label}  
                            label={pageStrings.accountSelfCreationPage.formItem_password_label} 
                        ></AccountSelfCreationConfigurationNoButton> 
                        <AccountSelfCreationConfiguration 
                            visibilityOptionValue={telephoneSelection} 
                            label={pageStrings.accountSelfCreationPage.formItem_phoneNumber_label} 
                            disabled={!accountSelfCreationEnabled}                          
                            onChange={handleTelephoneSelection}
                        ></AccountSelfCreationConfiguration>
                        <AccountSelfCreationConfiguration 
                            visibilityOptionValue={locationSelection} 
                            label={pageStrings.accountSelfCreationPage.formItem_location_label} 
                            disabled={!accountSelfCreationEnabled}                            
                            onChange={handleLocationSelection}
                        ></AccountSelfCreationConfiguration>
                        <AccountSelfCreationConfiguration 
                            visibilityOptionValue={titleSelection} 
                            label={pageStrings.accountSelfCreationPage.formItem_title_label}  
                            disabled={!accountSelfCreationEnabled}                                                       
                            onChange={handleTitleSelection}
                        ></AccountSelfCreationConfiguration>
                        <AccountSelfCreationConfiguration 
                            visibilityOptionValue={departmentSelection} 
                            label={pageStrings.accountSelfCreationPage.formItem_department_label}  
                            disabled={!accountSelfCreationEnabled}
                            onChange={handleDepartmentSelection}
                        ></AccountSelfCreationConfiguration>
                        <AccountSelfCreationConfiguration 
                            visibilityOptionValue={practiceAreaSelection} 
                            label={pageStrings.accountSelfCreationPage.formItem_practiceArea_label}  
                            disabled={!accountSelfCreationEnabled}                           
                            onChange={handlePracticeAreaSelection}
                        ></AccountSelfCreationConfiguration>
                        <AccountSelfCreationConfiguration 
                            visibilityOptionValue={userProfileSelection} 
                            label={pageStrings.accountSelfCreationPage.formItem_userProfile_label}  
                            disabled={!accountSelfCreationEnabled}                           
                            onChange={handleUserProfileSelection}
                        ></AccountSelfCreationConfiguration>
                    </div>                   
                </section>           
                <div className={styles['AccountSelfCreationDetailPane-blank']}></div>
                <Divider></Divider>
                <div className={styles['AccountSelfCreationDetailPane-actions']}>        
                    <div>
                        <FilledButton type={"submit"} value={pageStrings.accountSelfCreationPage.button_save_label} iconName="save"></FilledButton>
                    </div>
                </div>
            </Form>
        </>
    );

}


function visibilityOptionInitialValue(vo: number){
    if(vo)
    {
        if (vo.toString() === VisibilityOption.Required.toString()){
         
         return VisibilityOption.Required;
  
        } else if (vo.toString() === VisibilityOption.Optional.toString()) {
            
            return VisibilityOption.Optional;
            
        } else if (vo.toString() === VisibilityOption.Hidden.toString()){
            
            return VisibilityOption.Hidden;
            
        }
        
    }
    return VisibilityOption.Hidden;
  
  }

function approvalRequestEmailInitialValue(are: number){
 
    if(are)
    {

        if (are.toString() === ApprovalRequestEmail.DontSend.toString()){
            
            return ApprovalRequestEmail.DontSend;
            
        } else if (are.toString() === ApprovalRequestEmail.SendToSystemAdministrator.toString()){
            
            return ApprovalRequestEmail.SendToSystemAdministrator;
            
        } else if (are.toString() === ApprovalRequestEmail.SendToSpecificEmailAddress.toString()) {
            
            return ApprovalRequestEmail.SendToSpecificEmailAddress;
        }

    }
    
    
    return ApprovalRequestEmail.DontSend;
    
  }