import React ,{ useState } from 'react';
import { createComponent } from '@lit/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../icon/Icon';
 
import { MdOutlinedTextField } from '@material/web/textfield/outlined-text-field';

import styles from './OutlinedTextField.module.scss';
import {submitForm} from '../../form/Form';

const OutlinedTextFieldComponent = createComponent({
    tagName: 'md-outlined-text-field',
    elementClass: MdOutlinedTextField,
    react: React,
    events: {
        'onChange': 'change',
        'onInvalid': 'invalid',
        'onInput': 'input',
        'onKeyPress': 'keypress'
    }

});

interface IComponentProperties { 
    id?: string,
    autocomplete?: string,
    disabled?: boolean | undefined, // Apply Disabled state
    label: string, // Defines label text
    name: string, // Defines input name used when the form is submitted
    placeholder: string, // Defines placeholder text
    required?: boolean | undefined, // Apply Required 
    rows?: number | undefined, // Textareas can specify the initial number of rows
    supportingText?: string | undefined, // Supporting text
    type?: 'text' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'url' | 'textarea' | undefined, // Defines input type 
    value?: string|undefined, // Defines input value
    onChange?: (e: any) => void; // Defines onChange event handler
    onInput?: (e: any) => void;
    formid?: string | undefined;
}

function ErrorTrailingIcon(isInvalid: boolean) {
    if (!isInvalid) {
      return <></>;
    }
  
    return (
      <span className={styles['OutlinedTextField-errorTrailingIcon']} slot="trailing-icon">
        <Icon name="error" ariaHidden={true}></Icon>
      </span>
    );
  }
  
export function OutlinedTextField(props: IComponentProperties) {
    const [isInvalid, setIsInvalid] = useState(false);
    const [value, setValue] = useState(props.value || '');
    const { t } = useTranslation();
  
    // Events
    function handleChange(e: any) {
      const inputElement: HTMLInputElement = e.target;
  
      setValue(inputElement.value);
  
      inputElement.setCustomValidity('');
  
      setIsInvalid(false);
  
      e.target.reportValidity();
  
      if (props.onChange) {
        props.onChange(e);
      }
    }
  
    function handleInput (e: any){
      if (props.onInput){
        props.onInput(e);
      }
    }

    function handleKeyPress (e: any){

      if ((e.code === "Enter" || e.code ==="NumpadEnter" ) && props.formid )
        {
          submitForm(props.formid);
        }
      
    }

    function handleInvalid(e: any) {
      const inputElement: HTMLInputElement = e.target;
      const validityState: ValidityState = inputElement.validity;
  
      if (validityState.valueMissing) {
        inputElement.setCustomValidity('Required');
      }
  
      setIsInvalid(true);
    }
  
    return (
      <div className={styles['OutlinedTextField-container']}>
  
        <OutlinedTextFieldComponent    
          id={props.id}    
          label= {t(props.label)}
          className={`${styles['OutlinedTextField']}`}
          aria-label={t(props.label)}
          value={value}
          type={props.type}
          rows={props.rows}
          required={props.required}
          supportingText={props.supportingText}
          name={props.name}
          disabled={props.disabled === true}
          placeholder={t(props.placeholder)}
          onChange={handleChange}
          onInvalid={handleInvalid}
          onInput={handleInput}
          onKeyPress={handleKeyPress}
          hasTrailingIcon={isInvalid}
          autocomplete={props.autocomplete}
        >
          {ErrorTrailingIcon(isInvalid)}
        </OutlinedTextFieldComponent>
      </div>
    );
  }