import { ListItem } from "../../components";

interface IComponentProperties {
    listItemClicked?: Function
    userFullname: string
    userIdentifier: number
    userInitials: string
    userEmailAddress:string
}

export function UserListItem (props: IComponentProperties){

    return (
        <ListItem 
            type="button" 
            headline={props.userFullname} 
            supportingText={props.userEmailAddress} 
            leadingAvatarLabel={props.userInitials.toUpperCase()}
            buttonActivated={props.listItemClicked}></ListItem>
    );

}