import { HttpTransportType, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import urlService from '../../common/services/url.service';
import { appLogService  } from '../slices/appLog';
import { sessionStorageService } from '../../common/services/sessionStorage.service';

export abstract class SignalR {
    private connection: signalR.HubConnection;

    private _className: string = "SignalR";

    constructor(hubPath: string) {

        this.connection = new HubConnectionBuilder()
            .withUrl(urlService.buildHubUrl(`hubs/${hubPath}`), {
                headers: {
                    'LMS_Origin_Url': urlService.buildAppUrl('')
                },
                transport: HttpTransportType.WebSockets,
                skipNegotiation: true,
                withCredentials: true
            })
            .withAutomaticReconnect()
            .build();
    }

    public start() {

        if (this.connection.state === HubConnectionState.Disconnected){

            this.connection.start().catch(err => {
                appLogService.fatal(this._className, 'start', 'Exception', err.toString());
            });

        }
    }

    protected on(event: string, callback: (...args: any[]) => void) {
        
        if (this.connection.state === HubConnectionState.Connected){

            this.connection.on(event, callback);

        }
    }

    protected invoke(method: string, ...args: any[]) {
        
        if (this.connection.state === HubConnectionState.Connected){

            this.connection.invoke(method, ...args).catch(err => {
                appLogService.fatal(this._className, 'invoke', 'Exception', err.toString());
            });
            
        }
        
    }
}
