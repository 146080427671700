import React from "react";
import { createComponent } from "@lit/react";
import { MdListItem } from "@material/web/list/list-item";
import { Icon } from '../icon/Icon';

import styles from "./ListItem.module.scss";
import { Text } from "../../text/Text";

const ListItemComponent = createComponent({
    tagName: "md-list-item",
    elementClass: MdListItem,
    react: React,
});

interface IComponentProperties {
    buttonActivated?: Function,
    disabled?: boolean | undefined,
    headline?: string,
    supportingText?: string,
    href?: string | undefined,
    leadingAvatarLabel?: string | undefined,
    leadingIcon?: string | undefined,
    selected?: boolean | undefined,
    target?: "" |"_blank" | "_parent" | "_self" | "_top" | undefined,
    trailingIcon?: string | undefined
    type: "text" | "link" | "button",
}

export function ListItem(props: IComponentProperties) {

    function requestActivation() {
        
        if (props.type === 'button' && props.buttonActivated){
            props.buttonActivated();
        }
        
    }

    return (
        <>
        <ListItemComponent 
            className={`${styles["ListItem"]} ${props.selected?styles['is-selected']:''}`}
            href={props.href}
            target={props.target}
            type={props.type}
            tabIndex={0}
            disabled={props.disabled}
            onClick={requestActivation}
            >
            {
                props.leadingIcon && !props.leadingAvatarLabel &&
                <div className={styles['ListItem-slot']} slot="start">
                    <Icon name={props.leadingIcon} size={24}>
                    </Icon>
                </div> 
            }
            {
                !props.leadingIcon && props.leadingAvatarLabel &&
                <div className={styles['ListItem-slot']} slot="start">
                    <div className={styles['ListItem-leadingAvatar']}>
                        <Text fontStyle="title-medium" value={props.leadingAvatarLabel}></Text>
                    </div>
                </div> 
            }
            <div slot="headline">{props.headline}</div>
            {
                props.supportingText &&
                <div slot="supporting-text">{props.supportingText}</div>
            }
            {
                props.trailingIcon &&
                <div className={styles['ListItem-slot']} slot="end"><Icon name={props.trailingIcon} size={24}></Icon></div> 
            }           
        </ListItemComponent>
        </>
    );
}