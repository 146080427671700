import { createRef } from 'react';

import { NotFound } from "../app/errors/notFound/NotFound";
import { 
    CreateAccount,
    ForgotCredentials,
    ResetPassword,
    SignIn,
    SignOut,
    RevertIdentity
} from "../app/authentication";
import { AzureCallback } from '../app/authentication/ssoCallbacks/AzureCallback';

const routes = [
    {
        path: 'signin',
        nodeRef: createRef(),
        element: <SignIn />
    },
    {
        path: 'signout',
        nodeRef: createRef(),
        element: <SignOut />
    },
    {
        path: 'credentials/forgot',
        nodeRef: createRef(),
        element: <ForgotCredentials />
    },
    {
        path: 'account/create',
        nodeRef: createRef(),
        element: <CreateAccount></CreateAccount>
    },
    {
        path: 'credentials/reset',
        nodeRef: createRef(),
        element: <ResetPassword></ResetPassword>
    },    
    {
        path: 'identity/revert',
        element: <RevertIdentity />
    },
    {
        path: 'sso/callback',
        nodeRef: createRef(),
        element: <AzureCallback></AzureCallback>
    },
    {
        path: '*',
        element: <NotFound></NotFound>
    }
];

export default routes;