
import { useTranslation } from 'react-i18next';

import styles from './Text.module.scss';

interface IComponentProperties {
    value: string,
    fontStyle: 
        'body-large' | 'body-medium' | 'body-small' |
        'display-large' | 'display-medium' | 'display-small' |
        'headline-large' | 'headline-medium' | 'headline-small' |
        'label-large' | 'label-medium' | 'label-small' |
        'title-large' | 'title-medium' | 'title-small'
}

export function Text (props: IComponentProperties){

    const { t } = useTranslation();

    const cssModifier = `Text--${props.fontStyle}`;

    const textValue = t(props.value);

    return (
        <span className={`${styles['Text']} ${styles[cssModifier]}`}>
            {textValue}
        </span>
    );

}