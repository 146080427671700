import urlService from "../services/url.service";
import { useAppSelector } from "../../store";
import { useActionChecker, usePageStrings } from "../hooks";
import { SecurityProfileLevel } from "../../viewModels/application/configuration/securityProfileLevel";
import { useTranslation } from "react-i18next";

export interface INavigationItem {
    id: string,
    iconName: string,
    labelText: string,
    linkUrl: string
}

const useNavigationItems = (): INavigationItem[] => {
    const configuration = useAppSelector((state) => state.configuration);
    const authorization = useAppSelector((state) => state.authorization);
    const actionChecker = useActionChecker();
    const pageStrings = usePageStrings();
    const { t } = useTranslation();
    
    const homeNavItem = { 
        id: '1', 
        iconName: 'home', 
        labelText: t(pageStrings.navigationItems.text_Home), 
        linkUrl: urlService.buildLegacyAppUrl('default.aspx')
    };

    const calendarNavItem = { 
        id: '2',
        iconName: 'calendar_month',
        labelText: t(pageStrings.navigationItems.text_Calendar),
        linkUrl: urlService.buildLegacyAppUrl('app/calendar/calendar.aspx')
    };

    const libraryNavItem = {
        id: '3',
        iconName: 'account_balance',
        labelText: t(pageStrings.navigationItems.text_Library),
        linkUrl: urlService.buildLegacyAppUrl('app/library/library.aspx')
    };

    const myActivityNavItem = {
        id: '4',
        iconName: 'history_edu',
        labelText: t(pageStrings.navigationItems.text_MyActivity),
        linkUrl: urlService.buildLegacyAppUrl('app/history/history.aspx')
    };

    const reportsNavItem = {
        id: '5',
        iconName: 'data_table',
        labelText: t(pageStrings.navigationItems.text_Reports),
        linkUrl: urlService.buildLegacyAppUrl('app/analytics/analyticsrun.aspx')
    };

    const analyticsNavItem = {
        id: '6',
        iconName: 'analytics',
        labelText: t(pageStrings.navigationItems.text_Analytics),
        linkUrl: urlService.buildLegacyAppUrl('app/analytics/analyticsdashboard.aspx')
    };

    const usersNavItem = {
        id: '7',
        iconName: 'groups',
        labelText: t(pageStrings.navigationItems.text_Users),
        linkUrl: urlService.buildLegacyAppUrl('app/user/user.aspx')
    };

    const adminAreaNavItem = {
        id: '8',
        iconName: 'settings',
        labelText: t(pageStrings.navigationItems.text_Admin),
        linkUrl: urlService.buildLegacyAppUrl('app/admin/admin.aspx')
    };

    let navItems: INavigationItem[] = [];

    if (actionChecker.canDoAction("home.canAccess.policy")) {
        navItems.push(homeNavItem);
    }

    if (configuration.pages.calendar_IsDisplayed) {
        if (actionChecker.canDoAction("calendar.canAccess.policy")) {
            navItems.push(calendarNavItem);
        }
    }

    if (configuration.pages.library_IsDisplayed && authorization.maxSecurityProfileLevel > SecurityProfileLevel.CompanyAdministrator) {
        if (actionChecker.canDoAction("library.canAccess.policy")) {
            navItems.push(libraryNavItem);
        }
    }

    if (configuration.pages.history_IsDisplayed) {
        if (actionChecker.canDoAction("myActivity.canAccess.policy")) {
            navItems.push(myActivityNavItem);
        }
    }

    if (actionChecker.canDoAction("reports.canAccess.policy")) {
        navItems.push(reportsNavItem);
    }

    if (actionChecker.canDoAction("analytics.canAccess.policy")) {
        navItems.push(analyticsNavItem);
    }

    if (configuration.pages.users_IsDisplayed) {
        if (actionChecker.canDoAction("users.canAccess.policy")) {
            navItems.push(usersNavItem);
        }
    }

    if (actionChecker.canDoAction("adminArea.canAccess.policy")) {
        navItems.push(adminAreaNavItem);
    }

    
    
    return navItems;
}

export default useNavigationItems;
