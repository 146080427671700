import { NavLink } from "react-router-dom";

import { Form, submitForm } from '../../../common/components/form/Form';
import { SegmentedButton, SegmentedButtonSet, RadioGroup, Radio, FilledButton, OutlinedTextField, IconButton, Icon, Text } from "../../../common/components";
 
import { changePageTitle } from "../../../store/slices/layout/layout.slice";
import { Result, RootState, useAppDispatch, useAppSelector } from "../../../store";

// import { testTenant } from "../../../store/slices/test/test.slice";
// import { getConfiguration } from "../../../store/slices/configuration/configuration.slice";
//import { BasicConfiguration } from "../../../viewModels/application/basicConfiguration";
// import { IConfigurationState } from "../../../store/slices/configuration/configuration.types";

import { useEffect, useState } from "react";
import { configurationHub } from "../../../store/slices/configuration/configuration.hub";
import { lms3Service } from "../../../store/slices/lms3";
import { decodeToken, setAssumingIdentity, setAuthorizationToken, setUserPermissions } from "../../../store/slices/authorization";
import { authenticationService } from "../../../store/slices/authentication";
import { sessionStorageService } from "../../../common/services/sessionStorage.service";
import { SignInWithPasswordRequest } from "../../../viewModels/authentication/signInWithPasswordRequest";
import { SignInWithPasswordResponse } from "../../../viewModels/authentication/signInWithPasswordResponse";

// import env from '../../../config/env.json';

// import { Loading } from "../../../common/components/loading/Loading";
//import { stat } from "fs";

export default function Home (){
    
    const dispatch = useAppDispatch();
    const authorization = useAppSelector((state: RootState) => state.authorization);

    // const tenantTestResult = useSelector((state: RootState) => state.test.tenantTestResult);
    const [segmentedButtonValue, setSegmentedButtonValue] = useState('option1');

    function handleClick (){
        submitForm('formid');
    }
    
    function handleSubmit (args: any){
        console.log(args);

    }
    
    function handleTenantTest (){
        // dispatch(testTenant('www'));
        
    }

    function handleAssumeIdentity(){

        const userSessionId = authorization.userPermissions['Intellek.Lms.UserSessionId'];

        authenticationService.assumeIdentity({
            learnerId: 4
        }).then((response: Result<SignInWithPasswordResponse>) => {

            if (response.isSuccess){

                var claims = decodeToken(response.value.token);

                var assumedUserSessionId = claims['Intellek.Lms.UserSessionId'];

                lms3Service.setAdminSession(assumedUserSessionId, userSessionId).then(()=> {
                    console.log('signedIn as User');
                    dispatch(setAuthorizationToken(response.value.token));
                    sessionStorageService.setAuthorizationToken(response.value.token);
    
                    dispatch(setAssumingIdentity(userSessionId));
                }).catch(error => {
                    console.log('setAdminSession', error);
                });
            
            }

        });

    }

    function handleSuspenseTest() {
        console.log("hit suspense test");
        
        // setTimeout(() => {
            
        //     dispatch(getConfiguration());

        //     if (configurationResult != null)
        //     {
    
        //     }    
        // }, 3000);
        
    }

    function handleLms3WriteCookie() {
        var startTime = performance.now();
        lms3Service.WriteLms3Cookie(authorization.userPermissions["Intellek.Lms.UserSessionId"]).finally(() => {
            var endTime = performance.now();
        console.log(`WriteLms3Cookie Call took ${endTime - startTime} milliseconds`);
        });
    }

    function handleLms3RemoveCookie() {
        var startTime = performance.now();
        lms3Service.RemoveLms3Cookie();
        var endTime = performance.now();
        console.log(`RemoveLms3Cookie Call took ${endTime - startTime} milliseconds`);
    }
    
    function handleLms3WriteCookieApi() {
        // api version
        
        var startTime = performance.now();
        lms3Service.WriteLms3ApiCookie(authorization.userPermissions["Intellek.Lms.UserSessionId"]).finally(() => {
            var endTime = performance.now();    
            console.log(`WriteLms3ApiCookie Call took ${endTime - startTime} milliseconds`);
        });
        //var endTime = performance.now();
        
    }

    function handleLms3RemoveCookieApi() {
        // api version
        var startTime = performance.now();
        lms3Service.RemoveLms3ApiCookie();
        var endTime = performance.now();
        console.log(`RemoveLms3ApiCookie Call took ${endTime - startTime} milliseconds`);
    }

    useEffect(() => {
        dispatch(changePageTitle('Home'));
    });

    // let location = useLocation();
    // function handleEnv(){
    //     console.log(env.apiUrl);
    // }

    return (
        <>
            <h1>Home page</h1>
            {/* <FilledButton type="button" value="env" onClick={handleEnv}></FilledButton> */}

            <NavLink to="/library">Library</NavLink>
            <a href="http://localhost/app/admin/branding.aspx">LMS3</a>
            <h2>Icons</h2>
            <Icon name="home" size={20}></Icon>
            <Icon name="home" size={24}></Icon>
            <Icon name="home" size={40}></Icon>
            <Icon name="home" size={48}></Icon>
            <Icon name="home" size={24} isFilled></Icon>
            
            <section>
                <h3>Tests</h3>
                <FilledButton type="button" value="Tenant Test" onClick={handleTenantTest}></FilledButton>
                {/* <p className={styles['test-typography-body-large']}>{tenantTestResult}</p> */}

            </section>

            <section>
                <h3>Tests</h3>
                <FilledButton type="button" value="Assume Identity" onClick={handleAssumeIdentity}></FilledButton>
            </section>

            <section>
                <Text fontStyle="headline-small" value="Segmented buttons"></Text>
                <div style={{width: '300px'}}>
                    <SegmentedButtonSet ariaLabel="options">
                        <SegmentedButton labelText="option 1" selected={segmentedButtonValue === 'option1'} value={'option1'} onClick={() => setSegmentedButtonValue('option1')}></SegmentedButton>
                        <SegmentedButton labelText="option 2" disabled={true} selected={segmentedButtonValue === 'option2'} value={'option2'} onClick={() => setSegmentedButtonValue('option2')}></SegmentedButton>
                        <SegmentedButton labelText="option 3" selected={segmentedButtonValue === 'option3'} value={'option3'} onClick={() => setSegmentedButtonValue('option3')}></SegmentedButton>
                    </SegmentedButtonSet>
                </div>
            </section>

            <Form formId={'formid'} onSubmit={handleSubmit} hiddenSubmit={true}>
                <h2>Text Field</h2>
                <div style={{width: '300px'}}>

                    <OutlinedTextField label={"Passwddord"} name={"passdddword"} placeholder={"Enter password"} ></OutlinedTextField>
                </div>

                <section>
                    <Text fontStyle="headline-small" value="Radio buttons"></Text>
                    <RadioGroup label="group">
                        <Radio labelText="test1" name="groupname" checked={false} value="1" onChanged={(d: any) =>console.log(d)}></Radio>
                        <Radio labelText="test2" name="groupname" checked={false} value="2" onChanged={(d: any) =>console.log(d)}></Radio>
                        <Radio labelText="test3" name="groupname" checked={false} value="3" onChanged={(d: any) =>console.log(d)}></Radio>
                    </RadioGroup>
                </section>

                <FilledButton type="submit" value="submit" iconName={"save"}></FilledButton>
            </Form>

            <h2>Buttons</h2>
            <FilledButton type={"button"} value={"Save"} onClick={handleClick}></FilledButton>
            <h2>Icon buttons</h2>
            {/* <IconComponent >settings</IconComponent>
            <IconComponent  >&#xe834</IconComponent>
            <IconComponent tabIndex={-1}><span aria-label="home">&#xe88a</span></IconComponent> */}
            <IconButton iconName="home" tooltipText='Open Menu' onClick={handleClick} ariaLabel="Open Menu" autofocus></IconButton>
            <IconButton iconName="home" tooltipText='Open Menu' onClick={handleClick} ariaLabel="Open Menu" isDisabled></IconButton>
            <IconButton iconName="home" tooltipText='Open Menu' onClick={handleClick} ariaLabel="Open Menu" isSelected></IconButton>
            <IconButton iconName="home" tooltipText='Open Menu' onClick={handleClick} ariaLabel="Open Menu"></IconButton>
            <Text value="Display Large" fontStyle="display-large"></Text>
            <Text value="Display Medium" fontStyle="display-medium"></Text>
            <Text value="Display Small" fontStyle="display-small"></Text>
            <Text value="Headline Large" fontStyle="headline-large"></Text>
            <Text value="Headline Medium" fontStyle="headline-medium"></Text>
            <Text value="Headline Small" fontStyle="headline-small"></Text>
            <Text value="Title Large" fontStyle="title-large"></Text>
            <Text value="Title Medium" fontStyle="title-medium"></Text>
            <Text value="Title Small" fontStyle="title-small"></Text>
            <Text value="Body Large" fontStyle="body-large"></Text>
            <Text value="Body Medium" fontStyle="body-medium"></Text>
            <Text value="Body Small" fontStyle="body-small"></Text>
            <Text value="Label Large" fontStyle="label-large"></Text>
            <Text value="Label Medium" fontStyle="label-medium"></Text>
            <Text value="Label Small" fontStyle="label-small"></Text>
            <section>
            <FilledButton type="button" value="Suspense Test" onClick={handleSuspenseTest}></FilledButton>
            </section>
            <section>
            <FilledButton type={"button"} value={"Write LMS3 Cookie"} onClick={handleLms3WriteCookie}></FilledButton>
            <FilledButton type={"button"} value={"Remove LMS3 Cookie"} onClick={handleLms3RemoveCookie}></FilledButton>
            <FilledButton type={"button"} value={"Write LMS3 Cookie API"} onClick={handleLms3WriteCookieApi}></FilledButton>
            <FilledButton type={"button"} value={"Remove LMS3 Cookie API"} onClick={handleLms3RemoveCookieApi}></FilledButton>
            </section>

        </>
    );
}
