import { NavigationDrawerItem } from './NavigationDrawerItem';

import styles from './NavigationDrawer.module.scss';
import { useNavigationItems } from '../../../hooks';

export function NavigationDrawer(){

    const navigationItems = useNavigationItems();

    const navigationDrawerItems = navigationItems.map(item => {
        return  <NavigationDrawerItem key={item.id} labelText={item.labelText} iconName={item.iconName} 
            linkUrl={item.linkUrl} isActive={item.id === navigationItems[0].id}></NavigationDrawerItem>

    });

    return (
        <nav aria-label="Primary" className={`${styles['NavigationDrawer']}`}>
            <div className={styles["NavigationDrawer-background"]}></div>
            
                <div className={styles["NavigationDrawer-content"]}>
                <ul>
                    {navigationDrawerItems}
                </ul>
            </div>
            
            
        </nav>
    );

}