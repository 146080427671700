
import { useEffect, useState } from 'react';
import { FilledButton, OutlinedTextField, Form, Text, SelectOption, OutlinedSelect, BannerMessage } from '../../../common/components';
import { Action, ActionStatus, useAppDispatch, useAppSelector } from '../../../store';
// import { initDepartments } from '../../../store/slices/department';
import styles from './createAccount.module.scss';
import { getDepartments } from '../../../store/slices/department';
import { getUserProfiles } from '../../../store/slices/userprofile';
import { getJobTitles } from '../../../store/slices/jobTitle/jobTitle.slice';
import { getPracticeAreas } from '../../../store/slices/practiceArea';
import { useLayout, usePageStrings } from '../../../common/hooks';
import { getLocations } from '../../../store/slices/location';
import { VisibilityOption } from '../../../viewModels/configuration/visibilityOption';
import { authenticationService } from '../../../store/slices/authentication';
import { useNavigate } from 'react-router-dom';
import { addSnackbarMessage, SnackbarMessage } from '../../../store/slices/snackbar';


export function CreateAccount() {
    const pageStrings = usePageStrings();
   
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [createAccountAction, setCreateAccountAction] = useState(Action.idle());

    const department = useAppSelector(state => state.department);
    const location = useAppSelector(state => state.location);

    const practiceArea = useAppSelector(state => state.practiceArea);
    const jobTitle = useAppSelector(state => state.jobTitle);

    const userProfile = useAppSelector(state => state.userProfile);

    const configurationAccountSelfCreation = useAppSelector(state => state.configuration.accountSelfCreation);
        
    useLayout({
        authorizationLayoutConfiguration: 'doubleColumn'
    });

    useEffect(() => {
        if (location.status === ActionStatus.idle) {
            dispatch(getLocations());
        }
        if (department.status === ActionStatus.idle) {
            dispatch(getDepartments());
        }
        if (userProfile.status === ActionStatus.idle) {
            dispatch(getUserProfiles());
        }
        if (jobTitle.status === ActionStatus.idle) {
            dispatch(getJobTitles());
        }
        if (practiceArea.status === ActionStatus.idle) {
            dispatch(getPracticeAreas());
        }
    }, [department, jobTitle, userProfile, practiceArea, location, dispatch])


    const departmentSelectData = department.data.map(department => {
        return <SelectOption optionName={department.name} optionValue={department.rowGUID} ></SelectOption>
    });

    const practiceAreaSelectData = practiceArea.data.map(practiceArea => {
        return <SelectOption optionName={practiceArea.name} optionValue={practiceArea.rowGUID}></SelectOption>
    });

    const userProfileSelectData = userProfile.data.map(userProfile => {
        return <SelectOption optionName={userProfile.name} optionValue={userProfile.rowGUID}></SelectOption>
    });

    const jobTitleSelectData = jobTitle.data.map(jobTitle => {
        return <SelectOption optionName={jobTitle.name} optionValue={jobTitle.rowGUID}></SelectOption>
    });

    const locationSelectData = location.data.map(location => {
        return <SelectOption optionName={location.locationName} optionValue={location.rowGUID}></SelectOption>
    });


    const departmentIsVisible = configurationAccountSelfCreation.formConfiguration_Department !== VisibilityOption.Hidden
    const locationIsVisible = configurationAccountSelfCreation.formConfiguration_Location !== VisibilityOption.Hidden
    const practiceAreaIsVisible = configurationAccountSelfCreation.formConfiguration_PracticeArea !== VisibilityOption.Hidden
    
    const telephoneIsVisible = configurationAccountSelfCreation.formConfiguration_Telephone !== VisibilityOption.Hidden
    const titleIsVisible = configurationAccountSelfCreation.formConfiguration_Title !== VisibilityOption.Hidden
    const userProfileIsVisible = configurationAccountSelfCreation.formConfiguration_UserProfile !== VisibilityOption.Hidden
    const usernameIsVisible = configurationAccountSelfCreation.formConfiguration_Username !== VisibilityOption.Hidden

    let errorMessage = "";

    function handleSaveAuthenticationActivated(formData: any) {
        
        authenticationService.createAccount({
            department: formData['createaccount_department'],
            emailAddress: formData['createaccount_emailAddress'],
            firstname: formData['createaccount_firstName'],
            lastname: formData['createaccount_lastName'],
            location: formData['createaccount_location'],
            password: formData['createaccount_password'],
            phoneNumber: formData['createaccount_telephone'],
            practiceArea: formData['createaccount_practiceArea'],
            profile: formData['createaccount_profile'],
            title: formData['createaccount_title'],
            username: formData['createaccount_username']
        }).then(result => {
            console.log('handleSaveAuthenticationActivated',result);
            if (result.isSuccess) {
                let sb =  new SnackbarMessage(result.value,5) 
                dispatch(addSnackbarMessage(sb));
                setCreateAccountAction(Action.succeeded());
                navigate("/authorization/signin");
            }
            else{
                
                setCreateAccountAction(Action.failed(result.errorMessage));
                console.log(result);
                errorMessage = result.errorMessage;
                console.log(errorMessage);
                
            }
        });
    }

    return (
        <div className={styles['CreateAccount']}>
            <header>
                <h1 className={styles['CreateAccount-heading']}>
                    <Text fontStyle="headline-small" value={pageStrings.createNewUserPage.heading_createAccount_label}></Text>
                </h1>
            </header>

            {createAccountAction.message && createAccountAction.status === ActionStatus.failed &&
                <div className={styles['CreateAccount-errorMessage']}>
                    <BannerMessage messageType="error" message={createAccountAction.message}></BannerMessage>
                </div>
            }
            <Form onSubmit={handleSaveAuthenticationActivated}>

                <div className={styles['CreateAccount-formFields']}>

                    <OutlinedTextField id="createAccount-firstName" name="createaccount_firstName" required
                        label={pageStrings.createNewUserPage.text_createAccount_firstName_label}
                        placeholder={pageStrings.createNewUserPage.text_createAccount_firstName_label} >
                    </OutlinedTextField>

                    <OutlinedTextField name="createaccount_lastName" required
                        label={pageStrings.createNewUserPage.text_createAccount_lastName_label}
                        placeholder={pageStrings.createNewUserPage.text_createAccount_lastName_label}>
                    </OutlinedTextField>

                    <OutlinedTextField name="createaccount_emailAddress" required
                        label={pageStrings.createNewUserPage.text_createAccount_emailAddress_label}
                        type='email'
                        placeholder={pageStrings.createNewUserPage.text_createAccount_emailAddress_label}>
                    </OutlinedTextField>

                    {usernameIsVisible &&
                        <OutlinedTextField name="createaccount_username"
                            label={pageStrings.createNewUserPage.text_createAccount_userName_label}
                            placeholder={pageStrings.createNewUserPage.text_createAccount_userName_label}
                            required={configurationAccountSelfCreation.formConfiguration_Username === VisibilityOption.Required}>
                        </OutlinedTextField>
                    }

                    <OutlinedTextField name="createaccount_password" required
                        label={pageStrings.createNewUserPage.text_createAccount_password_label}
                        type='password'
                        placeholder={pageStrings.createNewUserPage.text_createAccount_password_label}>
                    </OutlinedTextField>

                    <OutlinedTextField name="createaccount_confirmPassword" required
                        type='password'
                        label={pageStrings.createNewUserPage.text_createAccount_confirmPassword_label}
                        placeholder={pageStrings.createNewUserPage.text_createAccount_confirmPassword_label}>
                    </OutlinedTextField>

                    {telephoneIsVisible &&
                        <OutlinedTextField name="createaccount_telephone"
                            label={pageStrings.createNewUserPage.text_createAccount_telephone_label}
                            type='tel'
                            placeholder={pageStrings.createNewUserPage.text_createAccount_telephone_label}
                            required={configurationAccountSelfCreation.formConfiguration_Telephone === VisibilityOption.Required}>
                        </OutlinedTextField>
                    }

                    {locationIsVisible &&
                        <OutlinedSelect name="createaccount_location"
                            label={pageStrings.createNewUserPage.text_createAccount_location_label} 
                            required={configurationAccountSelfCreation.formConfiguration_Location === VisibilityOption.Required}>
                            {locationSelectData}
                        </OutlinedSelect>
                    }

                    {titleIsVisible &&
                        <OutlinedSelect name="createaccount_title" 
                            label={pageStrings.createNewUserPage.text_createAccount_title_label}
                            required={configurationAccountSelfCreation.formConfiguration_Title === VisibilityOption.Required}>
                            {jobTitleSelectData}
                        </OutlinedSelect>
                    }

                    {departmentIsVisible &&
                        <OutlinedSelect name ='createaccount_department' label={pageStrings.createNewUserPage.text_createAccount_department_label}
                            required={configurationAccountSelfCreation.formConfiguration_Department === VisibilityOption.Required}>
                            {departmentSelectData}
                        </OutlinedSelect>
                    }

                    {practiceAreaIsVisible &&
                        <OutlinedSelect name="createaccount_practiceArea" 
                            label={pageStrings.createNewUserPage.text_createAccount_practiceArea_label}
                            required={configurationAccountSelfCreation.formConfiguration_PracticeArea === VisibilityOption.Required}>
                            {practiceAreaSelectData}
                        </OutlinedSelect>
                    }
                    {userProfileIsVisible &&
                        <OutlinedSelect name="createaccount_profile" 
                            label={pageStrings.createNewUserPage.text_createAccount_profile_label} 
                            required={configurationAccountSelfCreation.formConfiguration_UserProfile === VisibilityOption.Required}>
                            {userProfileSelectData}
                        </OutlinedSelect>
                    }
                </div>

                <div className={styles['CreateAccount-contentButton']}>

                    <FilledButton type='submit' value={pageStrings.createNewUserPage.button_create_label}></FilledButton>
                </div>

            </Form >
        </div >
    );
}