import { useEffect, useState } from "react";
import { authenticationService } from "../../../store/slices/authentication";
import { ActionStatus, Result, RootState, useAppDispatch, useAppSelector } from "../../../store";
import { setAssumingIdentity, setAuthorizationToken, setUserPermissions } from "../../../store/slices/authorization";
import { lms3Service } from "../../../store/slices/lms3";
import { sessionStorageService } from "../../../common/services/sessionStorage.service";
import styles from './RevertIdentity.module.scss';
import { SignInWithPasswordResponse } from "../../../viewModels/authentication/signInWithPasswordResponse";
import { appLogService } from "../../../store/slices/appLog";


export function RevertIdentity () {

    const dispatch = useAppDispatch();
    const authorization = useAppSelector((state: RootState) => state.authorization);
    const [revertIdentityAction, setRevertIdentityAction] = useState(ActionStatus.idle);

    useEffect(() => {
        if(revertIdentityAction === ActionStatus.idle)
            {
                revertIdentity();
            }
    }, []);

    function revertIdentity() {

        setRevertIdentityAction(ActionStatus.loading);

        authenticationService.revertIdentity().then((response: Result<SignInWithPasswordResponse>) => {        
            if (response.isSuccess){
               
                lms3Service.setLms4Cookie().then( ()=>{

                    dispatch(setAuthorizationToken(response.value.token));
                    dispatch(setUserPermissions(response.value.permissions)); 
                    dispatch(setAssumingIdentity(response.value.token));
    
                    sessionStorageService.setAuthorizationToken(response.value.token);
                    sessionStorageService.setUserPermissions(response.value.permissions); 

                    // redirect to query param returnUrl
                    const refUrl = new URLSearchParams(window.location.search).get('refUrl');

                    window.location.href = refUrl ?? '/profile/me';

                    setRevertIdentityAction(ActionStatus.succeeded);

                }).catch(error => {

                    setRevertIdentityAction(ActionStatus.failed);
                    window.location.href = '/authentication/signin'
                    appLogService.fatal('revertIdentity.tsx', 'useEffect', 'revertIdentity', error??'');
                });    
            }
        });
    }

    return (
        <section className={styles['revertIdentity']}>
            <h2>Reverting identity ...</h2>
        </section>
    );
}