import { useEffect } from "react";

import i18n from "../../../config/i18n";
import { ActionStatus, useAppDispatch, useAppSelector } from "../../../store";

import AppLayout from "../appLayout/AppLayout";
import AuthenticationLayout from "../authenticationLayout/AuthenticationLayout";
import { Splash } from "../../../app/splash/Splash";
import { UnexpectedError } from "../../../app/errors/unexpected/UnexpectedError";
import { configurationLoaded, setErrorMessage, startInitialLoad, translationFileLoaded } from "../../../store/slices/layout";
import { usePageStrings } from "../../hooks";
import { configurationHub, getConfiguration, getConfigurationBasic } from "../../../store/slices/configuration";
import themeService from "../../services/theme.service";

interface IComponentProperties{
    layoutType: 'external' | 'application'
}

export function MainLayout (props: IComponentProperties) {

    const dispatch = useAppDispatch();

    const configuration = useAppSelector((state) => state.configuration);
    const layout = useAppSelector((state) => state.layout);
    const pageStrings = usePageStrings();

    useEffect(() => {

        if (layout.status === ActionStatus.idle){

            dispatch(startInitialLoad());

            i18n.init((err: any) => {
                if (err) {
                    dispatch(setErrorMessage(pageStrings.general.error_Unexpected));
                } else {
                    dispatch(translationFileLoaded())
                }

            });
        }

        if (configuration.status === ActionStatus.idle){

            dispatch(props.layoutType === 'application'? 
                getConfiguration():
                getConfigurationBasic());
                         
        } else if (configuration.status === ActionStatus.failed){
            dispatch(setErrorMessage(pageStrings.general.error_Unexpected));
        } else if (configuration.status === ActionStatus.succeeded){
            dispatch(configurationLoaded());
            configurationHub.start();
        }
           

        const themeStyle = document.createElement('style');

        themeStyle.innerHTML = themeService.generateCssVariables({
             primaryKeyColor: configuration.branding.keyColor_primary,
             secondaryKeyColor: configuration.branding.keyColor_secondary
        });

        document.head.appendChild(themeStyle);

        return () => {
            document.head.removeChild(themeStyle);
        }

    }, [configuration, dispatch, layout, pageStrings, props]);
   
    if (layout.status === ActionStatus.failed){
        return (<UnexpectedError></UnexpectedError>);
    }

    if (layout.status === ActionStatus.loading){
        return (<Splash></Splash>);
    }

    return (
        <>        

            {props.layoutType === 'application' && 
                <AppLayout></AppLayout>
            }

            {props.layoutType === 'external' && 
                <AuthenticationLayout configuration={layout.appLayout.authorizationConfiguration}></AuthenticationLayout>
            }

        </>
    );

}


