import { useState } from "react";

import { SegmentedButton, SegmentedButtonSet, Text } from "../../../../../common/components";
import { SSOProviderAzure } from "./ssoProviderAzure/SSOProviderAzure";
import { SSOProviderOKTA } from "./ssoProviderOKTA/SSOProviderOKTA";
import { SSOProviderADFS } from "./ssoProviderADFS/SSOProviderADFS";
import { Authentication } from "../../../../../viewModels/configuration/settings/authentication";

import styles from "./AuthenticationDetailPaneSSOProviders.module.scss";
import { usePageStrings } from "../../../../../common/hooks";

const sso_Azure: string = "Azure";
const sso_Okta: string = "Okta";
const sso_ADFS: string = "ADFS";

interface IComponentProperties {
  authenticationSettings: Authentication;
  ssoProviderChanged: (args: ISsoProviderChangedArgs) => void
}

type ISsoProviderChangedArgs = {
    adfs_isEnabled: boolean,
    azure_isEnabled: boolean,
    okta_isEnabled: boolean,
}

export function AuthenticationDetailPaneSSOProviders(props: IComponentProperties) {
  
  const pageStrings = usePageStrings();
  const [ssoSelected, setSSOSelected] = useState(ssoSelectedInitialValue(props.authenticationSettings));

  function handleSSOChanged(selectedValue: any) {

    setSSOSelected(selectedValue);

    props.ssoProviderChanged({
      adfs_isEnabled: selectedValue === sso_ADFS,
      azure_isEnabled: selectedValue === sso_Azure,
      okta_isEnabled: selectedValue === sso_Okta,
    });
  }
  
  return (
    <section className={styles["AuthenticationDetailPaneSSOProviders"]}>      
      <h3 >
        <span className={styles["AuthenticationDetailPaneSSOProviders-ssoProvider-headerText"]}>
          {pageStrings.authenicationPage.buttonGroup_ssoProvider_label}
        </span>
      </h3>

      <div
        className={
          styles["AuthenticationDetailPaneSSOProviders-ssoProviderButton"]
        }
      >
        <SegmentedButtonSet ariaLabel="SSO Provider Options">
          <SegmentedButton
            labelText={pageStrings.authenicationPage.buttonGroup_ssoProvider_azure_label}
            selected={ssoSelected === sso_Azure}
            value={sso_Azure}
            onClick={handleSSOChanged}
          ></SegmentedButton>
          <SegmentedButton
            labelText={pageStrings.authenicationPage.buttonGroup_ssoProvider_okta_label}
            selected={ssoSelected === sso_Okta}
            value={sso_Okta}
            onClick={handleSSOChanged}
          ></SegmentedButton>
          <SegmentedButton
            labelText={pageStrings.authenicationPage.buttonGroup_ssoProvider_adfs_label}
            selected={ssoSelected === sso_ADFS}
            value={sso_ADFS}
            onClick={handleSSOChanged}
          ></SegmentedButton>
        </SegmentedButtonSet>
      </div>

      {ssoSelected === sso_Azure && (
        <SSOProviderAzure
          clientId={props.authenticationSettings.azure_clientId}
          clientSecret={props.authenticationSettings.azure_clientSecret}
          // clientSecretExpiryDate={
          //   props.authenticationSettings.azure_clientSecretExpiryDate
          // }
          loginNameProperty={
            props.authenticationSettings.azure_loginNameProperty
          }
          tenantName={props.authenticationSettings.azure_tenantName}
        ></SSOProviderAzure>
      )}

      {ssoSelected === sso_Okta && (
        <SSOProviderOKTA
          accountName={props.authenticationSettings.okta_accountName}
          appId={props.authenticationSettings.okta_appId}
          appSecret={props.authenticationSettings.okta_appSecret}
          // appSecretExpiryDate={
          //   props.authenticationSettings.okta_appSecretExpiryDate
          // }
          authorizationServer={
            props.authenticationSettings.okta_authorizationServer
          }
          loginNameProperty={
            props.authenticationSettings.okta_loginNameProperty
          }
        ></SSOProviderOKTA>
      )}

      {ssoSelected === sso_ADFS && (
        <SSOProviderADFS
          clientId={props.authenticationSettings.adfs_clientId}
          loginNameProperty={
            props.authenticationSettings.adfs_loginPropertyName
          }
          orgUri={props.authenticationSettings.adfs_orgUri}
        ></SSOProviderADFS>
      )}
    </section>
  );


function ssoSelectedInitialValue(authenticationSettings: Authentication){
  

  if(authenticationSettings.adfs_isEnabled)
    {
      return sso_ADFS;
    }
  if(authenticationSettings.azure_isEnabled)
    {
      return sso_Azure;
    }
  if(authenticationSettings.okta_isEnabled)
    {
      return sso_Okta;
    }
  
    return sso_ADFS;
  }
}